import React from "react"
import { ReactComponent as Location } from "../../styles/icons/map-marker-alt-solid.svg"
import { ReactComponent as Phone } from "../../styles/icons/phone-alt-solid.svg"
import { ReactComponent as Email } from "../../styles/icons/envelope-solid.svg"
import { ReactComponent as Fax } from "../../styles/icons/fax-solid.svg"
import ContactForm from "../../components/contactForm"

import { Helmet } from "react-helmet"
import Layout from "../../components/layout"

export default function Contact() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us | Clean Air Filter</title>
      </Helmet>
      <section className="contact-links">
        <h1 style={{ width: "100%" }}>
          <span className="tail">
            <span className="head">Contact</span> Clean Air Filter, Co.
          </span>
        </h1>

        <article className="contact-info-container">
          <p>
            <Phone></Phone> <a href="tel:712.748.3642">(+1) 712.748.3642</a>
          </p>
          <p>
            <Fax></Fax> 712.748.3643
          </p>
          <p>
            <Email></Email>{" "}
            <a href="mailto:caf@cleanairfilter.com">caf@cleanairfilter.com</a>
          </p>
          <p>
            <Location></Location>{" "}
            <a href="https://www.google.com/maps/place/Clean+Air+Filter+Co/@41.822226,-95.347858,16z/data=!4m5!3m4!1s0x0:0xfe13b3d251b6685d!8m2!3d41.822226!4d-95.347858?hl=en">
              2203 HWY 59 Po Box 212 Defiance, IA 51527
            </a>
          </p>
          <div className="contact-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2973.345447956997!2d-95.348872!3d41.820859!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfe13b3d251b6685d!2sClean%20Air%20Filter%20Co!5e0!3m2!1sen!2sus!4v1594054135396!5m2!1sen!2sus"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              // tabIndex="0"
              title="caf location"
            ></iframe>
          </div>
        </article>
        <ContactForm
          formName="contact"
          subject="Form submission from Clean Air Filter Contact form"
        ></ContactForm>
      </section>
    </Layout>
  )
}
